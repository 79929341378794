<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Guias</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Guías</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row justify-content-between">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#modal-form-import"
                      style="cursor: pointer"
                      v-if="$store.getters.can('hidrocarburos.guias.create')"
                      @click="$refs.GuiaImportExcel.limpiarModal()"
                    >
                      Importar Excel
                      <i class="fas fa-file-import"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-dark"
                      data-toggle="modal"
                      data-target="#modal-form-rank"
                      style="cursor: pointer"
                      v-if="$store.getters.can('hidrocarburos.guias.create')"
                      @click="$refs.GuiaRangos.limpiarForm()"
                    >
                      Rangos de Guias
                      <i class="fab fa-hackerrank"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-info"
                      data-toggle="modal"
                      data-target="#modal_aside_right"
                      style="cursor: pointer"
                      v-if="$store.getters.can('hidrocarburos.guias.create')"
                    >
                      Movimiento Guia
                      <i class="fas fa-people-carry"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn bg-navy"
                      @click="getIndex(), getGrafica()"
                    >
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="resetFilter(), getGrafica()"
                    >
                      <i class="fas fa-broom"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modal-guia_individual"
                      style="cursor: pointer"
                      @click="$refs.GuiaIndividual.resetForm()"
                      v-if="$store.getters.can('hidrocarburos.guias.create')"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body pb-2">
                <div class="row justify-content-center">
                  <div class="form-group col-md-2">
                    <label>Número de Guía</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.numero"
                      @keyup="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label>Transportadora</label>
                    <v-select
                      v-model="slct_transportadora"
                      class="form-control form-control-sm p-0"
                      placeholder="Razón Social"
                      label="razon_social"
                      :options="listasForms.transportadoras"
                      @input="getIndex, getGrafica"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-5">
                    <label>Sitio</label>
                    <v-select
                      v-model="slct_sitio"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="getIndex, getGrafica"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Producto</label>
                    <v-select
                      v-model="slct_producto"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.productos"
                      @input="getIndex, getGrafica"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @keyup="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Remolque</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.remolque"
                      @keyup="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Destino</label>
                    <v-select
                      v-model="slct_destino"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="getIndex, getGrafica"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo Producto</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_producto"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_producto in listasForms.tipo_productos"
                        :key="tipo_producto.numeracion"
                        :value="tipo_producto.numeracion"
                      >
                        {{ tipo_producto.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Ingreso Guía Pegaso</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Expedición Inicial</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_expedicion_inicial"
                      @input="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Expedición Final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_expedicion_final"
                      @input="getIndex, getGrafica"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo Empresa</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_empresa"
                      @change="getIndex, getGrafica"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="tipoEmpresa in listasForms.tipoEmpresas"
                        :key="tipoEmpresa.numeracion"
                        :value="tipoEmpresa.numeracion"
                      >
                        {{ tipoEmpresa.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex, getGrafica"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="escaner">Escaner guia</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.escaner_guia"
                        style="font-size: 12px; background-color: #fff"
                        @change="getIndex()"
                      >
                        <option value="">todos</option>
                        <option value="1">Con Escáner</option>
                        <option value="2">Sin Escáner</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="guias"
                  class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  style="font-size: 0.85em"
                >
                  <thead class="bg-dark">
                    <tr>
                      <th>Tipo Guía</th>
                      <th class="col-2">Número de Guía - DV</th>
                      <th class="col-2">Anulación Guía</th>
                      <th class="col-2">Guía Reemplaza</th>
                      <th class="col-2">Tipo Producto</th>
                      <th class="col-4">Transportadora</th>
                      <th class="col-4">Sitio</th>
                      <th class="col-3">Producto</th>
                      <th>Vehículo</th>
                      <th>Remolque</th>
                      <th class="col-3">Destino</th>
                      <th>Fecha Ingreso Guía</th>
                      <th>Fecha Expedición</th>
                      <th>Fecha Salida</th>
                      <th
                        v-if="
                          $store.getters.can('hidrocarburos.guias.indexPdf')
                        "
                      >
                        Escáner Guía
                      </th>
                      <th class="col-3">Tipo Empresa</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in guias.data" :key="item.id">
                      <!-- Tipo Guía -->
                      <td class="text-center">
                        <span class="badge bg-info" v-if="item.det_guias.length"
                          >Viaje</span
                        >
                        <span
                          class="badge bg-primary"
                          v-else-if="item.det_guias_regalias.length"
                          >Regalias/Terceros</span
                        >
                        <span class="badge bg-warning" v-else>
                          Sin Utilizar Guía</span
                        >
                      </td>
                      <!-- Número Guía -->
                      <td :class="editDv ? 'p-1' : ''">
                        <div v-if="!editDv">
                          {{ item.numero }}
                          {{
                            item.digito_verificacion != null
                              ? `- ${item.digito_verificacion}`
                              : ""
                          }}
                          <button
                            type="button"
                            v-if="!editDv"
                            class="btn btn-sm bg-navy px-1 py-0 float-right"
                            @click="editDigito(item)"
                          >
                            <i class="fas fa-edit" title="editar DV"></i>
                          </button>
                        </div>
                        <div class="row" v-else>
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="digito"
                              v-if="editDv.id == item.id"
                              :class="
                                $v.digito.$invalid ? 'is-invalid' : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-sm-4 text-right">
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  editDv &&
                                    $store.getters.can(
                                      'hidrocarburos.guias.edit'
                                    ) &&
                                    !$v.digito.$invalid &&
                                    editDv.id == item.id
                                "
                                title="Guardar Dv"
                                @click="saveEditData(item, 1)"
                              >
                                <i class="fas fa-save"></i>
                              </button>
                              <button
                                class="btn btn-sm btn-danger"
                                v-if="editDv && editDv.id == item.id"
                                title="Salir"
                                @click="editDv = null"
                              >
                                <i class="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <!-- Anulación Guía -->
                      <td v-if="item.guia_anulada" class="text-center">
                        {{ item.guia_anulada.numero }} -
                        {{
                          item.guia_anulada.digito_verificacion
                            ? item.guia_anulada.digito_verificacion
                            : ""
                        }}
                        <span
                          type="button"
                          class="bagde bg-gradient-info btn-xs"
                          style="cursor: pointer"
                          @click="motivoAnulacion(item)"
                        >
                          <i class="fas fa-comment"></i> Motivo
                        </span>
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-warning">Sin Anulación</span>
                      </td>
                      <!-- Guía Reemplaza -->
                      <td v-if="item.guia_reemplaza" class="text-center">
                        {{ item.guia_reemplaza.numero }} -
                        {{
                          item.guia_reemplaza.digito_verificacion
                            ? item.guia_reemplaza.digito_verificacion
                            : ""
                        }}
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-warning">Sin Reemplazo</span>
                      </td>
                      <!-- Tipo Producto -->
                      <td class="text-center">
                        {{ item.tipo_producto ? item.nTipoProducto : "" }}
                      </td>
                      <!-- Transportadora -->
                      <td>
                        {{
                          item.det_guias.length &&
                          item.det_guias[0].transportadora
                            ? item.det_guias[0].transportadora.razon_social
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0].transportadora
                            ? item.det_guias_regalias[0].transportadora
                                .razon_social
                            : ""
                        }}
                      </td>
                      <!-- Sitio -->
                      <td>{{ item.sitio.nombre }}</td>
                      <!-- Producto Liquido -->
                      <td>
                        {{
                          item.det_guias.length &&
                          item.det_guias[0].producto_liquido
                            ? item.det_guias[0].producto_liquido.nombre
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0].producto_liquido
                            ? item.det_guias_regalias[0].producto_liquido.nombre
                            : ""
                        }}
                      </td>
                      <!-- Vehículo -->
                      <td>
                        {{
                          item.det_guias.length && item.det_guias[0].vehiculo
                            ? item.det_guias[0].vehiculo.placa
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0].vehiculo
                            ? item.det_guias_regalias[0].vehiculo
                            : ""
                        }}
                      </td>
                      <!-- Remolque -->
                      <td>
                        {{
                          item.det_guias.length && item.det_guias[0].remolque
                            ? item.det_guias[0].remolque.placa
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0]
                            ? item.det_guias_regalias[0].remolque
                            : ""
                        }}
                      </td>
                      <!-- Destino -->
                      <td>
                        {{
                          item.det_guias.length &&
                          item.det_guias[0].sitio_destino
                            ? item.det_guias[0].sitio_destino.nombre
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0].sitio_destino
                            ? item.det_guias_regalias[0].sitio_destino
                            : ""
                        }}
                      </td>
                      <!-- Creación de la Guía -->
                      <td class="text-center">
                        {{ item.created_at.slice(0, 10) }}
                      </td>
                      <!-- Fecha Expedición -->
                      <td class="text-center">
                        {{
                          item.det_guias.length &&
                          item.det_guias[0].fecha_expedicion
                            ? item.det_guias[0].fecha_expedicion.slice(0, 10)
                            : item.det_guias_regalias.length &&
                              item.det_guias_regalias[0].fecha_expedicion
                            ? item.det_guias_regalias[0].fecha_expedicion.slice(
                                0,
                                10
                              )
                            : ""
                        }}
                      </td>
                      <!-- Fecha Salida -->
                      <td class="text-center">
                        {{
                          item.cierre_guias.length &&
                          item.cierre_guias[0].fecha_hora_descargue
                            ? item.cierre_guias[0].fecha_hora_descargue
                            : ""
                        }}
                      </td>
                      <!-- Escaner -->
                      <td
                        class="text-center"
                        v-if="
                          $store.getters.can('hidrocarburos.guias.indexPdf')
                        "
                      >
                        <a
                          target="_blank"
                          :href="uri_docs + item.escaner_guia"
                          v-if="item.escaner_guia"
                        >
                          <i
                            class="fa fa-check text-success"
                            data-toggle="tooltip"
                            data-placement="right"
                          ></i>
                        </a>
                        <a
                          target="_blank"
                          :href="
                            /* item.escaner_guia
                              ? uri_docs + item.escaner_guia
                              : */ item.viaje_id
                              ? uri_docs + item.viaje.escaner_guia
                              : item.det_guias_regalias[0] != undefined
                              ? uri_docs +
                                item.det_guias_regalias[0].escaner_guia
                              : ''
                          "
                          v-else-if="
                            (item.estado != 3 &&
                              item.viaje &&
                              item.viaje.escaner_guia) ||
                              (item.det_guias_regalias[0] != undefined &&
                                item.det_guias_regalias[0].escaner_guia)
                          "
                        >
                          <i
                            class="fa fa-check text-success"
                            data-toggle="tooltip"
                            data-placement="right"
                          ></i>
                        </a>
                        <i
                          v-else
                          class="fa fa-times-circle text-danger"
                          data-toggle="tooltip"
                          data-placement="right"
                        ></i>
                      </td>
                      <!-- Tipo Empresa -->
                      <td>
                        <div v-if="!editedItem">
                          {{ item.tipoEmpresa }}
                          <button
                            type="button"
                            v-if="!editedItem"
                            class="btn btn-sm bg-navy px-1 py-0 float-right"
                            @click="editData(item)"
                          >
                            <i
                              class="fas fa-edit"
                              title="editar tipo empresa"
                            ></i>
                          </button>
                        </div>
                        <div class="row" v-else>
                          <div class="col-sm-8">
                            <select
                              class="form-control form-control-sm"
                              v-model="item.tipo_empresa"
                              v-if="editedItem.id == item.id"
                            >
                              <option value>Seleccione...</option>
                              <option
                                v-for="tipoEmpresa in listasForms.tipoEmpresas"
                                :key="tipoEmpresa.numeracion"
                                :value="tipoEmpresa.numeracion"
                              >
                                {{ tipoEmpresa.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="col-sm-4 text-right">
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy px-2 py-1"
                                v-if="
                                  editedItem &&
                                    $store.getters.can(
                                      'hidrocarburos.guias.edit'
                                    ) &&
                                    editedItem.id == item.id
                                "
                                title="Guardar tipo empresa"
                                @click="saveEditData(item, 2)"
                              >
                                <i class="fas fa-save"></i>
                              </button>
                              <button
                                class="btn btn-sm btn-danger px-2 py-1"
                                v-if="editedItem && editedItem.id == item.id"
                                title="Salir"
                                @click="editedItem = null"
                              >
                                <i class="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <!-- Estado Guía -->
                      <td>
                        <div v-if="item.estado == 1">
                          <h6>
                            <span
                              class="badge badge-success"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              @click="llenarModal(item.id, item.estado)"
                              style="cursor: pointer"
                            >
                              <i class="fas fa-check-circle"></i>
                              {{ item.estadoGuia }}
                            </span>
                          </h6>
                        </div>
                        <div v-else-if="item.estado == 2">
                          <h6>
                            <span
                              class="badge badge-primary"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              @click="llenarModal(item.id, item.estado)"
                              style="cursor: pointer"
                            >
                              <i class="far fa-check-circle"></i>
                              {{ item.estadoGuia }}
                            </span>
                          </h6>
                        </div>
                        <div v-else-if="item.estado == 3">
                          <h6>
                            <span class="badge badge-warning">
                              <i class="fas fa-times-circle"></i>
                              {{ item.estadoGuia }}
                            </span>
                          </h6>
                        </div>
                        <div v-else-if="item.estado == 4">
                          <h6>
                            <span class="badge badge-danger">
                              <i class="far fa-times-circle"></i>
                              {{ item.estadoGuia }}
                            </span>
                          </h6>
                        </div>
                        <div v-else>
                          <h6>
                            <span class="badge badge-navy">
                              <i class="far fa-times-circle"></i>
                              {{ item.estadoGuia }}
                            </span>
                          </h6>
                        </div>
                      </td>
                      <!-- Acciones -->
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            v-if="
                              item.estado == 3 &&
                                $store.getters.can(
                                  'hidrocarburos.guias.indexPdf'
                                )
                            "
                            :class="
                              item.escaner_guia
                                ? 'btn btn-success'
                                : 'btn btn-warning'
                            "
                            data-toggle="modal"
                            data-target="#modal-escaner-guia"
                            @click="modalEscanerGuía(item)"
                          >
                            <i class="far fa-file"></i>
                          </button>
                          <button
                            v-else-if="
                              (item.viaje ||
                                item.det_guias_regalias[0] != undefined) &&
                                $store.getters.can(
                                  'hidrocarburos.guias.indexPdf'
                                ) &&
                                item.estado == 2
                            "
                            data-toggle="modal"
                            data-target="#modal-escaner-guia"
                            @click="modalEscanerGuía(item)"
                            type="button"
                            :class="
                              (item.viaje && item.viaje.escaner_guia) ||
                              (item.det_guias_regalias[0] != undefined &&
                                item.det_guias_regalias[0].escaner_guia)
                                ? 'btn btn-success'
                                : 'btn btn-warning'
                            "
                          >
                            <i class="far fa-file"></i>
                          </button>
                          <a
                            :id="`botonImp${item.det_guias[0].id}`"
                            type="button"
                            class="btn bg-navy"
                            v-if="
                              item.tipo_operacion == 1 &&
                                item.det_guias.length > 0 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.printGuia'
                                )
                            "
                            @click="print(item.det_guias[0].id)"
                          >
                            <i class="fas fa-print"></i>
                          </a>
                          <a
                            :id="`botonImp${item.det_guias_regalias[0].id}`"
                            type="button"
                            class="btn bg-navy"
                            @click="
                              printGuiaRegalias(item.det_guias_regalias[0].id)
                            "
                            v-if="
                              item.tipo_operacion == 2 &&
                                item.det_guias_regalias.length > 0 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.printGuia'
                                )
                            "
                          >
                            <i class="fas fa-print"></i>
                          </a>
                          <button
                            class="btn btn-info"
                            v-if="
                              item.tipo_operacion == 1 &&
                                item.det_guias.length > 0 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.diligenciarGuia'
                                )
                            "
                            @click="verDetGuia(item.det_guias[0], item.viaje)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-info"
                            v-if="
                              item.tipo_operacion == 2 &&
                                item.det_guias_regalias.length > 0 &&
                                $store.getters.can(
                                  'hidrocarburos.ventanasOperaciones.diligenciarGuia'
                                )
                            "
                            @click="
                              verDetGuiaRegalias(item.det_guias_regalias[0])
                            "
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="guias.total">
                  Mostrando del
                  <b>{{ guias.from }}</b> al <b>{{ guias.to }}</b> de un total:
                  <b>{{ guias.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger"
                    >No hay registros para mostrar</span
                  >
                </div>
                <pagination
                  :data="guias"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-3">
                  <div class="small-box bg-secondary">
                    <div class="inner">
                      <h3 style="font-size: 3.5em">Guías</h3>
                      <h3 style="font-size: 1.1em">
                        Disponibles:
                        <span
                          class="badge badge-pill badge-success float-right"
                        >
                          {{ cantidadDisp }}
                        </span>
                      </h3>
                      <h3 style="font-size: 1.1em">
                        Usadas:
                        <span
                          class="badge badge-pill badge-primary float-right"
                        >
                          {{ cantidadUsa }}
                        </span>
                      </h3>
                      <h3 style="font-size: 1.1em">
                        Anuladas:
                        <span
                          class="badge badge-pill badge-warning float-right"
                        >
                          {{ cantidadAnu }}
                        </span>
                      </h3>
                      <h3 style="font-size: 1.1em">
                        Extraviadas:
                        <span class="badge badge-pill badge-danger float-right">
                          {{ cantidadExt }}
                        </span>
                      </h3>
                      <h3 style="font-size: 1.1em">
                        Cerradas:
                        <span class="badge badge-pill bg-navy float-right">
                          {{ cantidadCer }}
                        </span>
                      </h3>
                      <h3 style="font-size: 1.1em">
                        Sin Imprimir:
                        <span class="badge badge-pill badge-light float-right">
                          {{ cantidadImp }}
                        </span>
                      </h3>
                    </div>
                    <div class="icon">
                      <i class="ion ion-pie-graph"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="small-box bg-light">
                    <pie-chart
                      :data="chartData"
                      :options="chartOptionsPie"
                    ></pie-chart>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="small-box bg-light">
                    <highcharts :options="chartOptions"></highcharts>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="small-box bg-light">
                    <GraficaEstadoGuias ref="GraficaEstadoGuias" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GuiaEstado ref="GuiaEstado" />
        <GuiaImportExcel ref="GuiaImportExcel" />
        <GuiaRangos ref="GuiaRangos" />
        <GuiaMovimiento ref="GuiaMovimiento" />
        <GuiaIndividual ref="GuiaIndividual" />
        <ViajeEscanerGuia ref="ViajeEscanerGuia" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import GuiaEstado from "./GuiaEstado";
import GuiaImportExcel from "./GuiaImportExcel";
import GuiaRangos from "./GuiaRangos";
import GuiaMovimiento from "./GuiaMovimiento";
import GuiaIndividual from "./GuiaIndividual";
import PieChart from "./../PieChart.js";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import { Chart } from "highcharts-vue";
import GraficaEstadoGuias from "./GraficaEstadoGuias";
import ViajeEscanerGuia from "../../basico/viajes/ViajeEscanerGuia";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

export default {
  name: "Guias",

  components: {
    Loading,
    pagination,
    vSelect,
    GuiaEstado,
    GuiaImportExcel,
    GuiaRangos,
    GuiaMovimiento,
    GuiaIndividual,
    PieChart,
    GraficaEstadoGuias,
    highcharts: Chart,
    ViajeEscanerGuia,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: ["Estados Guias"],
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Cantidad De Guias",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE GUIAS POR ESTADO",
          align: "center",
        },

        series: [
          {
            type: "column",
            name: ["Disponibles"],
            data: [],
            color: "#00C851",
          },
          {
            type: "column",
            name: "Usadas",
            data: [],
            color: "#0099CC",
          },
          {
            type: "column",
            name: "Anuladas",
            data: [],
            color: "#ffbb33",
          },
          {
            type: "column",
            name: "Extraviadas",
            data: [],
            color: "#ff4444",
          },
          {
            type: "column",
            name: "Cerradas",
            data: [],
            color: "#143358",
          },
          {
            type: "column",
            name: "Sin Imprimir",
            data: [],
            color: "#EDEDED",
          },
        ],

        credits: {
          enabled: false,
        },
        hoverBorderWidth: 10,
      },
      chartOptionsPie: {
        hoverBorderWidth: 10,
      },
      animation: {
        animateRotate: false,
        animateScale: true,
      },
      chartData: {
        labels: [
          "Disponibles",
          "Usadas",
          "Anuladas",
          "Extraviadas",
          "Cerradas",
          "Sin Imprimir",
        ],
        datasets: [
          {
            backgroundColor: [
              "#00C851",
              "#0099CC",
              "#ffbb33",
              "#ff4444",
              "#143358",
              "#EDEDED",
            ],
            borderWidth: 2,
          },
        ],
      },
      filtros: {},
      guias: {},
      slct_transportadora: [],
      slct_sitio: [],
      slct_producto: [],
      slct_destino: [],
      listasForms: {
        transportadoras: [],
        sitios: [],
        productos: [],
        tipoEmpresas: [],
        estados: [],
        tipo_productos: [],
      },
      editedItem: null,
      editDv: null,
      digito: null,
      cargando: false,
      cantidadDisp: "",
      cantidadUsa: "",
      cantidadAnu: "",
      cantidadExt: "",
      cantidadCer: "",
      cantidadImp: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    return {
      digito: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1),
        numeric,
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      let me = this;
      me.cargando = true;
      this.filtros.sitio_id = this.slct_sitio ? this.slct_sitio.id : null;
      this.filtros.transportadora_id = this.slct_transportadora
        ? this.slct_transportadora.id
        : null;
      this.filtros.producto_liquido_id = this.slct_producto
        ? this.slct_producto.id
        : null;
      this.filtros.sitio_destino_id = this.slct_destino
        ? this.slct_destino.id
        : null;

      axios
        .get("/api/hidrocarburos/guias?page=" + page, {
          params: this.filtros,
        })
        .then(function(response) {
          me.cargando = false;
          var respuesta = response.data;
          me.guias = respuesta.guias;
        });
    },

    getTransportadoras() {
      let me = this;
      me.listasForms.transportadoras = [];
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: { linea_negocio_id: 2 },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSitios() {
      let me = this;
      me.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getProductos() {
      let me = this;
      me.listasForms.productos = [];
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.productos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoEmpresas() {
      axios.get("/api/lista/58").then((response) => {
        this.listasForms.tipoEmpresas = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/37").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoProductos() {
      axios
        .get("/api/lista/7", {
          params: {
            in_number: [1, 6, 8],
          },
        })
        .then((response) => {
          this.listasForms.tipo_productos = response.data;
        });
    },

    resetFilter() {
      this.slct_sitio = null;
      this.slct_transportadora = null;
      this.slct_producto = null;
      this.slct_destino = null;
      this.filtros = {};
      this.getIndex();
    },

    print(idDetGuia) {
      $(`#botonImp${idDetGuia}`)
        .attr(
          "href",
          process.env.VUE_APP_API_URL + "/toPrint/guia/" + idDetGuia
        )
        .attr("target", "_blank");
    },

    printGuiaRegalias(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guiaRegalias/" + idDetGuia,
        "_blank"
      );
    },

    verDetGuia(detGuia, viaje) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasForm",
        params: {
          accion: "Show",
          actoGuia: 1,
          data_edit: detGuia,
          viaje: viaje,
        },
      });
    },

    verDetGuiaRegalias(detGuia) {
      return this.$router.push({
        name: "/Hidrocarburos/DetGuiasRegaliasForm",
        params: {
          accion: "Show",
          actoGuia: 1,
          data_edit: detGuia,
        },
      });
    },

    editData(item) {
      this.editedItem = item;
    },

    editDigito(item) {
      this.editDv = item;
      this.digito = item.digito_verificacion;
    },

    saveEditData(item, numeracion) {
      this.$parent.cargando = true;
      let params = {
        item: item,
        digito: this.digito,
        numeracion: numeracion,
      };
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/guias/editTipoEmpresa",
        data: params,
      })
        .then(async () => {
          this.$parent.cargando = false;
          this.editedItem = null;
          this.editDv = null;
          this.digito = null;
          await this.getIndex();
          this.$swal({
            icon: "success",
            title: "El dato se edito exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          let res = Object.assign({}, e);
          this.$swal({
            icon: "error",
            title:
              "Ocurrio un error, vuelva a intentarlo..." +
              res.response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    llenarModal(id, estado) {
      this.$refs.GuiaEstado.llenar_modal(id, estado);
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    async getGrafica() {
      this.filtros.sitio_id = this.slct_sitio ? this.slct_sitio.id : null;
      this.filtros.transportadora_id = this.slct_transportadora
        ? this.slct_transportadora.id
        : null;
      this.filtros.producto_liquido_id = this.slct_producto
        ? this.slct_producto.id
        : null;
      this.filtros.sitio_destino_id = this.slct_destino
        ? this.slct_destino.id
        : null;

      let me = this;

      await axios
        .get("/api/hidrocarburos/guias/graficas", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          let respuesta = response.data;
          me.cantidadDisp = respuesta.cantDisp;
          me.cantidadUsa = respuesta.cantUsa;
          me.cantidadAnu = respuesta.cantAnu;
          me.cantidadExt = respuesta.cantExt;
          me.cantidadCer = respuesta.cantCer;
          me.cantidadImp = respuesta.cantImp;
          //data de grafica de pie
          me.chartData.datasets[0].data[0] = me.cantidadDisp;
          me.chartData.datasets[0].data[1] = me.cantidadUsa;
          me.chartData.datasets[0].data[2] = me.cantidadAnu;
          me.chartData.datasets[0].data[3] = me.cantidadExt;
          me.chartData.datasets[0].data[4] = me.cantidadCer;
          me.chartData.datasets[0].data[5] = me.cantidadImp;

          //data de grafica de barras
          me.chartOptions.series[0].data = [me.cantidadDisp];
          me.chartOptions.series[1].data = [me.cantidadUsa];
          me.chartOptions.series[2].data = [me.cantidadAnu];
          me.chartOptions.series[3].data = [me.cantidadExt];
          me.chartOptions.series[4].data = [me.cantidadCer];
          me.chartOptions.series[5].data = [me.cantidadImp];
          me.$refs.GraficaEstadoGuias.getIndex(respuesta);
        });
    },

    modalEscanerGuía(viaje) {
      let viaje_type =
        viaje.escaner_guia || viaje.estado == 3
          ? "App\\Guia"
          : viaje.viaje_id && viaje.viaje
          ? "App\\Viaje"
          : viaje.det_guias_regalias[0] != undefined && !viaje.viaje_id
          ? "App\\DetGuiaRegalias"
          : "App\\Guia";
      let data =
        viaje_type == "App\\DetGuiaRegalias"
          ? viaje.det_guias_regalias[0]
          : viaje_type == "App\\Viaje"
          ? viaje.viaje
          : viaje;
      this.$refs.ViajeEscanerGuia.llenar_modal_escaner_guia(data, viaje_type);
    },

    motivoAnulacion(guia) {
      this.$swal({
        icon: "info",
        title: guia.justificacion,
        position: "top-end",
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: false,
        customClass: {
          popup: "swal-custom",
        },
        /* toast: true, */
        /* timer: 6000,
        timerProgressBar: true,*/
      });
    },
  },

  beforeMount() {
    this.getEstados();
    this.getTipoEmpresas();
    this.getSitios();
    this.getTransportadoras();
    this.getProductos();
    this.getTipoProductos();
  },

  mounted() {
    this.getIndex();
    this.getGrafica();
  },
};
</script>
<style>
.swal-custom {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #007bff;
}
</style>
